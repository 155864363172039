import {_get} from "@api/doinsport/services/httpService";
import { URL_CLIENT } from "../index";

const URI = '/stats';

export const getClientStats = (clientId, fromDate, toDate) => _get(
  URL_CLIENT +
    '/' +
    clientId +
    URI +
    '?from=' + fromDate
    )
;
