<template>
  <b-card
    :class="classes"
  >
    <div class="wrapper">
      <b-row>
        <b-col :cols="cols">
          <LeftLayout
            :client="client"
            :total-bookings="totalBookings"
            :total-cancellations="totalCancellations"
            @on:date-update="onDateUpdate"
          />
        </b-col>
        <div v-if="cols === '6'" class="split-layout__divider">
          <div class="split-layout__rule"></div>
          <div class="split-layout__rule"></div>
        </div>
        <b-col>
          <right-layout
            :client="client"
            :card-levels="cardLevels"
            @reload:related-activities="loadStats"
          />
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import {getClientStats} from "@api/doinsport/services/client/stats/stats.api";

export default {
  data: ()=> ({
    cardLevels: [],
    totalBookings: 0,
    totalCancellations: 0,
  }),
  components: {
    LeftLayout: () => import('./LeftLayout'),
    RightLayout: () => import('./RightLayout'),
  },
  props: {
    classes: {
      type: String,
      default: 'ml-4'
    },
    client: {
      type: Object,
      default: () => {
      },
    }
  },
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'];
    },
    cols() {
      return this.innerWidth <= 991 ? '12' : '6';
    }
  },
  methods: {
    onDateUpdate(scope) {
      this.loadCustomerStats(scope.searchDate, scope.toDate);
    },
    loadCustomerStats(fromDate, toDate) {
      getClientStats(this.$route.params.id, fromDate, toDate)
        .then((response) => {
          this.totalBookings = response.data.totalBookings;
          this.totalCancellations = response.data.totalCancellations;
          this.cardLevels = response.data.activities;
        })
      ;
    },
    loadStats() {
      this.loadCustomerStats(this.$toTimezone(this.client.createdAt).format('YYYY-MM-DD'), this.$toTimezone(this.$moment.utc()).format('YYYY-MM-DD'));
    }
  },
  created() {
    this.loadStats();
  },
}
</script>
<style scoped>
.card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #C4C8D066;
  border-radius: 8px;
  opacity: 1;
}

.card .card-body {
  padding-top: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1rem;
}

</style>
